import type { MetaFunction } from "@remix-run/node";
import { Form } from "@remix-run/react";
import Input from "~/components/Input";

export const meta: MetaFunction = () => {
  return [{ title: "El Agora" }, { name: "description", content: "El Agora" }];
};

export default function Index() {
  return (
    <div className="flex h-full flex-col">
      <div className="my-auto">
        <h1 className="pb-4 pl-2 text-6xl">El Agora</h1>
        <Form action="/search">
          <Input name="q" required placeholder="Search..." />
        </Form>
      </div>
    </div>
  );
}
